import * as React from "react"
import { 
  StaticImage 
} from "gatsby-plugin-image"

import { 
  PlusIcon 
} from '@heroicons/react/24/outline'

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import IntroTitle from "../../../components/wcc/IntroTitle"
import AppSelector from "../../../components/wcc/AppSelector"
import WCCIntro from "../../../components/wcc/WCCIntro"
import AppStoreLinks from "../../../components/AppStoreLinks"

const Gurray = () => {

  return (
    <Layout bgClass="bg-black" fgMode="light">
      <Seo 
        title="Gurray"
        description="The world's first Indigenous Australian Language keyboard extension"
      />
      <IntroTitle />
      <AppSelector />
      <div className="w-full pt-8 pb-16 text-white bg-black">
        <div className="grid max-w-screen-lg grid-cols-[1fr,1.2fr,1fr] gap-4 mx-auto">
          <div className="grid pt-16 pl-6">
            <section>
              <h2 className="mb-4 text-2xl font-bold">A full system experience</h2>
              <p>
                We wouldn't compromise on the user's experience so we built an keyboard
                experience to match iOS and Android standards.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Seamless</h2>
              <p>
                Switch between all other keyboards like Emoji 😀 or your default
                keyboard using the system experience. You'd never know that you
                are using a third party keyboard.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Choice of languages</h2>
              <p>
                The language program works with various countries and are integrating
                each one of them into the keyboard extension.
              </p>
            </section>
          </div>
          <div className="flex items-center justify-center">
            <StaticImage
              height={730}
              width={363}
              alt="Gurray Keyboard Demonstration"
              src="../../../images/iphone-gurray.png" 
              placeholder="blurred"/>
          </div>
          <div className="grid pt-16 pr-6">
            <section>
              <h2 className="mb-4 text-2xl font-bold">Autocomplete</h2>
              <p>
                Anomaly worked with KeyboardKit's developer to create an autocomplete
                feature, and contributed it back to the open source project.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Open Source goodness</h2>
              <p>
                As part of the development of Gurray we sponsored two open source
                projects to make sure we were able to give back as much as possible.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Built for the future</h2>
              <p>
                We know how fast software moves and are dedicated to keep the
                experience up to date for security and performance.
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="py-8 bg-gray-200">
          <section className="flex flex-col items-center justify-start max-w-screen-md mx-auto text-center">
            <div className="flex items-center">
                <StaticImage
                    height={128}
                    width={128}
                    alt="Logo KeyboardKit"
                    src="../../../images/icon-keyboardkit.png" 
                    placeholder="blurred"/>
                <PlusIcon className="w-8 h-8 mx-4 text-gray-800" />
                <StaticImage
                    height={128}
                    width={128}
                    alt="Logo KeyboardKit"
                    src="../../../images/icon-florisboard.png" 
                    placeholder="blurred"/>
            </div>
            <div className="pt-8 mx-4 lg:mx-auto">
                <h3 className="text-2xl font-semibold">Powered by KeyboardKit &amp; FlorisBoard</h3>
                <p className="pt-2 text-gray-700">
                    Building a custom keyboard is a daunting task.<br/>
                    The work of <a href="https://danielsaidi.com" className="underline">Daniel Saidi</a> and <a href="https://patrickgold.dev" className="underline">Patrick Goldinger</a> are here to save the day.
                    <br/><br/>
                    KeyboardKit and FlorisBoard are powerful libraries for building custom keyboards for iOS and Android, respectively.
                    Our team worked closely with Open Source project maintainers Daniel Saidi and Patrick Goldinger to bring Gurray to life on
                    iOS and Android.
                    <br/><br/> 
                    Anomaly is proud to sponsor the development of both these projects.
                </p>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                  <a href="https://github.com/KeyboardKit/KeyboardKit" className="flex items-center justify-center mt-10 text-lg">
                    Visit KeyboardKit on Github
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </a>
                  <a href="https://github.com/florisboard/florisboard" className="flex items-center justify-center mt-10 text-lg">
                    Visit FlorisBoard on Github
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </a>
                </div>
            </div>
          </section>
      </div>
      <AppStoreLinks 
            iOSStoreLink="https://apps.apple.com/us/app/gurray/id1513495735"
            googlePlayStoreLink="https://play.google.com/store/apps/details?id=au.com.wcclp.gurray"/>

      <WCCIntro/>
    </Layout>
 );
};
export default Gurray
